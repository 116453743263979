<template>
  <div class="event-details-wrapper studio-page">
    <div class="container" v-if="event">
      <EventNameComponent
        class="h2 c-charcoal"
        :eventName="event.event_name"
        :isCancelled="event.is_cancelled"
      />
      <div v-if="canShowRecording">
        <HMFilePreview :file="getRecording" />
        <div
          class="overflow-hidden"
          v-if="event.event_description || event.event_description_new"
        >
          <hr class="my-4" />
          <h2 class="mt-4">{{ $t('EVENTS.LABEL.DESCRIPTION') }}</h2>
          <HMCustomContent
            class="mt-4"
            :html="event.event_description"
            :json="event.event_description_new"
          />
        </div>
        <h4 v-if="hasAttachments" class="mt-4 mb-3">
          {{ $t('MEDIA_LIBRARY.TITLE.ATTACHMENTS') }}
        </h4>
        <div class="attachments">
          <HMAttachment
            class="mt-3"
            v-for="attachment in event.attachments"
            :key="attachment.id"
            :attachment="attachment"
          />
        </div>
      </div>
      <div v-else>
        <HMStudioHeader
          class="darken-50 mt-4 bg-charcoal rounded"
          v-if="studio"
          :image="headerImage"
          :allowEmptyImage="true"
        >
          <template v-slot:extra>
            <div class="live-tag" v-if="isSessionStarted && !isExpired">
              <HMTag class="bg-charcoal c-white" title="">
                <img
                  src="/assets/images/event-is-live.svg"
                  class="live-icon"
                />
                <span class="ms-2">{{ $t('EVENTS.LABEL.LIVE') }}</span>
              </HMTag>
            </div>
            <div class="live-tag" v-if="isExpired && recordingAvailable">
              <HMTag class="bg-charcoal c-white" title="">
                <i class="fal fa-video"></i>
                <span class="ms-2">{{ $t('EVENTS.LABEL.RECORDED') }}</span>
              </HMTag>
            </div>
          </template>
          <div
            class="d-flex flex-column flex-nowrap justify-content-center align-items-center"
          >
            <EventCountdown
              v-if="canDisplayCountdown"
              class="c-white"
              :date="event.date_start"
              @end="countdownEnded"
            />
            <div v-if="isCancelled">
              <h1 class="c-white">
                {{ $t('EVENTS.TEXT.EVENT_HAS_BEEN_CANCELLED') }}
              </h1>
            </div>
            <div v-else-if="isExpired">
              <h1 class="c-white">
                {{ $t('EVENTS.LABEL.EVENT_IS_EXPIRED') }}
              </h1>
              <div v-if="recordingAvailable" class="text-center">
                <span class="c-white" v-if="isClassCardEvent && !hasAccess">
                  {{
                    $t('EVENTS.LABEL.REGISTER_WITH_CLASSCARD_TO_VIEW_RECORDING')
                  }}
                </span>
                <span class="c-white" v-else>
                  {{ $t('EVENTS.LABEL.REGISTER_TO_VIEW_RECORDING') }}
                </span>
              </div>
            </div>
            <div v-else>
              <div class="text-center">
                <h1 v-if="isWaitingToStart" class="c-white">
                  <span v-if="!event.has_available_recordings">
                    <span v-if="isInPersonEvent">
                      {{ $t('EVENTS.LABEL.EVENT_IS_LIVE') }}
                    </span>
                    <span v-else>
                      {{ $t('EVENTS.LABEL.WAITING_FOR_INSTRUCTOR_TO_START') }}
                    </span>
                  </span>
                  <span v-else>
                    {{ $t('EVENTS.LABEL.RECORDING_IS_READY') }}
                  </span>
                </h1>
                <h1 v-else-if="isSessionStarted" class="c-white">
                  {{ $t('EVENTS.LABEL.EVENT_IS_LIVE') }}
                </h1>
                <span v-if="needsAccess && !isBeforeEvent" class="c-white">
                  {{ $t('EVENTS.LABEL.YOU_CAN_STILL_ACCESS_THIS_EVENT') }}
                </span>
              </div>
            </div>
            <StudioEventActionComponent
              :event="event"
              :isLoggedIn="isLoggedIn"
              :registering="registering"
              :unregistering="unregistering"
              @joinSession="joinSession"
              @registerForEvent="registerForEvent"
              @registerForEventWaitlist="registerForEventWaitlist"
              @registerForRecording="registerForRecording"
              @registerForFreeEvent="registerForFreeEvent"
              @unregisterFromEvent="showUnregisterFromEventModal"
              @unregisterFromEventWaitlist="showUnregisterFromEventWaitlistModal"
              @goToShop="goToShop"
              @login="openLoginModal"
              class="mt-3 d-none d-md-flex flex-column align-items-center justify-content-center c-white"
            />
          </div>
        </HMStudioHeader>
        <div
          class="mt-4 d-flex flex-column align-items-center justify-content-center d-md-none"
        >
          <StudioEventActionComponent
            :event="event"
            :isLoggedIn="isLoggedIn"
            :registering="registering"
            :unregistering="unregistering"
            @joinSession="joinSession"
            @registerForEvent="registerForEvent"
            @registerForEventWaitlist="registerForEventWaitlist"
            @registerForRecording="registerForRecording"
            @registerForFreeEvent="registerForFreeEvent"
            @unregisterFromEvent="unregisterFromEvent"
            @unregisterFromEventWaitlist="unregisterFromEventWaitlist"
            @goToShop="goToShop"
            @login="openLoginModal"
          />
        </div>
        <hr class="mt-4 d-block d-md-none mb-0" />
        <div class="info-card event-space">
          <div class="info-card-title">
            <div
              class="d-flex justify-content-between align-items-center flex-wrap info-card-header"
            >
              <StudioEventDateTimeComponent
                :startTime="shortTime(event.event_start_datetime)"
                :endTime="shortTime(event.event_end_datetime)"
                :date="shortDate(event.event_start_datetime)"
                :day="dayOfWeek(event.event_start_datetime)"
                :location="event.location_address"
              />
              <div>
                <div v-if="!isCancelled && !isExpired">
                  <button
                    class="add-to-calendar destyled-button p-0 pt-2 md:pt-0"
                    id="add-to-calendar"
                  >
                    <i class="fa fa-calendar-o c-coral me-2"></i>
                    {{ $t('EVENTS.LABEL.ADD_TO_CALENDAR') }}
                  </button>
                  <HMPopover
                    target="add-to-calendar"
                    placement="right"
                  >
                    <HMPopoverMenuItem>
                      <a
                        class="add-to-calendar"
                        :href="addToGoogleCalendarLink"
                        target="_blank"
                        v-if="addToGoogleCalendarLink"
                      >
                        {{ $t('EVENTS.LABEL.ADD_TO_GOOGLE_CALENDAR') }}
                      </a>
                    </HMPopoverMenuItem>
                    <HMPopoverMenuItem>
                      <a class="add-to-calendar" :href="icalLink" target="_blank">
                        {{ $t('EVENTS.LABEL.DOWNLOAD_ICAL_FILE') }}
                      </a>
                    </HMPopoverMenuItem>
                  </HMPopover>
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-column justify-content-start align-items-start my-4 mb-1"
              v-if="enablePhonePrompt"
            >
              <hr />
              <StudentPhoneNumberComponent
                title="EVENTS.TEXT.WOULD_YOU_LIKE_TO_RECEIVE_SMS"
                buttonTitle="EVENTS.BUTTON.ADD_MY_PHONE_NUMBER"
              />
            </div>
            <hr class="mt-0" />
            <div
              class="d-flex flex-row justify-content-between align-items-center flex-wrap w-100 mt-4"
            >
              <div
                class="d-flex flex-row justify-content-start align-items-center"
                v-if="hasInstructors"
              >
                <HMInstructorsStack
                  :instructors="getSubstituteOrInitialIntructors"
                  size="30px"
                  class="me-2"
                />
                <div>
                  {{
                    $t(
                      'PRODUCTS.LABELS.INSTRUCTORS_VARIANT',
                      { cnt: getNumberOfSubstituteOrInitialIntructors },
                      getNumberOfSubstituteOrInitialIntructors,
                    )
                  }}
                </div>
                <HMTag
                  v-if="numberOfSubstituteInstructors > 0"
                  class="smallest ms-2"
                >
                  {{
                    $t(
                      'EVENTS.LABEL.SUBSTITUTE_VARIANT',
                      numberOfSubstituteInstructors
                    )
                  }}
                </HMTag>
              </div>
              <StudioEventTypeComponent
                class="mt-2"
                :event="event"
                :small="false"
              />
            </div>
            <span v-if="event.usedSessionText">
              {{ event.usedSessionText }}
            </span>
          </div>
          <div v-if="isLimitedToSpecificClassCards">
            <hr class="my-4" />
            <div
              class="d-flex flex-row justify-content-end align-items-center flex-wrap"
            >
              <span>{{
                $t(
                  'EVENTS.LABEL.LIMITED_TO_CLASSCARDS_VARIANT',
                  isLimitedToSpecificClassCards
                )
              }}</span>
              <HMTag
                v-for="classcard in event.classcards"
                :key="classcard.id"
                class="ms-2"
              >
                <span>{{ classcard.product_name }}</span>
              </HMTag>
            </div>
          </div>
          <div
            class="overflow-hidden"
            v-if="event.event_description || event.event_description_new"
          >
            <hr class="my-4" />
            <h2 class="mt-4">{{ $t('EVENTS.LABEL.DESCRIPTION') }}</h2>
            <HMCustomContent
              class="mt-4"
              :html="event.event_description"
              :json="event.event_description_new"
            />
          </div>
          <h4 v-if="hasAttachments" class="mt-4 mb-3">
            {{ $t('MEDIA_LIBRARY.TITLE.ATTACHMENTS') }}
          </h4>
          <div class="attachments">
            <HMAttachment
              class="mt-3"
              v-for="attachment in event.attachments"
              :key="attachment.id"
              :attachment="attachment"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container text-center" v-if="!event && !loadError">
      <HMSpinner class="info-card-loader" />
    </div>
    <div class="container" v-if="!event && loadError">
      <div class="info-card">
        <h1 class="text-danger p50">{{ loadError }}</h1>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { mapState } from 'pinia';
import LegalWaiverModal from '@/components/shop/LegalWaiverModal.vue';
import { errorToMessage } from '@/utils/errors';
import HMInstructorsStack from '@/components/common/HMInstructorsStack.vue';
import StudioEventTypeComponent from '@/components/events/StudioEventTypeComponent.vue';
import * as EventUtils from '@/utils/events';
import StudioEventDateTimeComponent from '@/components/events/StudioEventDateTimeComponent.vue';
import EventNameComponent from '@/components/events/EventNameComponent.vue';
import StudioEventActionComponent from '@/components/events/StudioEventActionComponent.vue';
import EventCountdown from '@/components/events/EventCountdown.vue';
import StudentLogin from '@/components/signup/StudentLogin.vue';
import HMDialog from '@/components/common/HMDialog.vue';
import HMCustomContent from '@/components/common/HMCustomContent.vue';
import RefreshData from '@/mixins/refreshData';
import * as ics from 'ics';
import moment from '@/services/moment';
import { saveAs } from '@/utils/save-file';
import { metaTitles } from '@/utils/meta-utils';
import { trackFBPixelStudioFreeEventRegistration } from '@/services/analytics';
import { formatDate } from '@/utils/format';
import HMFilePreview from '@/components/common/HMFilePreview.vue';
import Cookies from 'js-cookie';
import StudentPhoneNumberComponent from '@/components/settings/StudentPhoneNumberComponent.vue';
import { EVENT_TYPE_LOCAL } from '@/utils/events';
import SignContractModal from '@/components/contracts/SignContractModal.vue';
import { useStudioStore, useEventStore, useUserStore } from '@/stores';

export default {
  name: 'StudioEventDetails',
  metaInfo() {
    return {
      title: this.metaTitle,
    };
  },
  mixins: [RefreshData],
  components: {
    HMFilePreview,
    HMCustomContent,
    StudioEventActionComponent,
    HMInstructorsStack,
    StudioEventTypeComponent,
    StudioEventDateTimeComponent,
    EventNameComponent,
    EventCountdown,
    StudentPhoneNumberComponent,
  },
  async setup() {
    const route = useRoute();
    const eventId = route.params.event_id;
    const { $studioURL } = useNuxtApp();
    const eventStore = useEventStore();
    const studioStore = useStudioStore();
    definePageMeta({
      name: 'studio-event-details',

      isStudio: true,
      hideInstagramSlider: true,
    })

    try {
      const { data: eventBasic } = await useAsyncData('eventBasic', () => eventStore.getStudioEventBasic({
        studioURL: $studioURL,
        eventId: eventId,
      }));

      useSeoMeta({
        ogTitle: `${eventBasic.value.event_name} | ${studioStore.currentStudio.studio_name}`,
        ogImage: eventBasic.value.cover_photo?.image_url,
      })
    } catch (error) {
      console.error(error);
    }
  },
  computed: {
    ...mapState(useEventStore, {
      event: 'eventDetails',
      eventProducts: 'eventProducts',
      eventInstructors: 'eventInstructors',
      eventSubstituteInstructors: 'eventSubstituteInstructors',
      addToGoogleCalendarLink: 'addToGoogleCalendarLink',
    }),
    ...mapState(useStudioStore, {
      isTwilioConnected: 'isTwilioConnected',
      studio: 'currentStudio',
    }),
    ...mapState(useUserStore, {
      studentPhone: 'studentPhone',
      isLoggedIn: 'isLoggedIn',
    }),
    metaTitle() {
      return this.event?.event_name
        ? `${this.event?.event_name} — ${this.studio?.studio_name}`
        : null;
    },
    isInPersonEvent() {
      return this.event.event_type === EVENT_TYPE_LOCAL;
    },
    sessionStarted() {
      return this.event.status === 'session-started';
    },
    isCancelled() {
      return this.event.status === 'cancelled';
    },
    needsAccess() {
      return this.event.accessNotAllowed;
    },
    hasClasscardAccess() {
      return this.event.status === 'classcard-access';
    },
    isFreeEvent() {
      return this.event.is_free_event;
    },
    isClassCardEvent() {
      return this.event.is_punchable_event;
    },
    hasAccess() {
      return this.event.has_access;
    },
    isRegistrationAllowed() {
      return this.event.is_register_allowed;
    },
    isWaitingToStart() {
      return this.event.isWaiting;
    },
    isBeforeEvent() {
      return this.event.isBeforeEvent;
    },
    isExpired() {
      return this.event.status === 'expired';
    },
    recordingAvailable() {
      return this.event.recordings.length > 0;
    },
    canShowRecording() {
      return (
        (this.isExpired || this.isWaitingToStart) &&
        this.recordingAvailable &&
        ((this.event.registration_required && this.event.is_registered) ||
          (!this.event.registration_required && this.hasAccess))
      );
    },
    isSessionStarted() {
      return this.event.isStarted;
    },
    studioURL() {
      return this.$studioURL;
    },
    eventId() {
      return this.$route.params.event_id;
    },
    headerImage() {
      return this.event?.cover_photo || this.studio?.cover_photo;
    },
    numberOfSubstituteInstructors() {
      return this.eventSubstituteInstructors?.length || 0;
    },
    numberOfInstructors() {
      return this.eventInstructors?.length || 0;
    },
    getSubstituteOrInitialIntructors() {
      if (this.numberOfSubstituteInstructors)
        return this.eventSubstituteInstructors;

      return this.eventInstructors;
    },
    getNumberOfSubstituteOrInitialIntructors() {
      return this.getSubstituteOrInitialIntructors?.length || 0;
    },
    hasInstructors() {
      return this.numberOfInstructors + this.numberOfSubstituteInstructors > 0;
    },
    isLimitedToSpecificClassCards() {
      return this.event.classcards?.length;
    },
    canDisplayCountdown() {
      if (this.isCancelled) return false;
      if (this.isExpired) return false;
      if (this.isSessionStarted) return false;
      if (this.isWaitingToStart) return false;

      return this.isBeforeEvent;
    },
    justPurchased() {
      return this.$route.query.purchased;
    },
    isEventFull() {
      if (!this.event.registration_required) return false;
      if (this.event.registration_limit === 0) return false;
      if (this.event.registration_limit > this.event.number_of_registrations)
        return false;

      return true;
    },
    canRegisterEvent() {
      if (this.needsAccess) return false;
      if (!this.isLoggedIn) return false;
      if (this.event.is_cancelled) return false;
      if (this.event.isExpired) return false;
      if (this.event.is_registered) return false;
      if (this.isEventFull) return false;
      if (!this.isRegistrationAllowed) return false;

      return this.hasClasscardAccess || this.event.registration_required;
    },
    getRecording() {
      return [...this.event.recordings].sort(
        (a, b) => b.video_length - a.video_length
      )[0];
    },
    hasAttachments() {
      return this.event?.attachments?.length;
    },
    isRegistered() {
      return this.event.is_registered;
    },
    enablePhonePrompt() {
      if (!this.isTwilioConnected) return false;
      if (!this.event.sms_notifications_enabled) return false;
      if (this.studentPhone) return false;

      return this.isRegistered;
    },
    icalLink() {
      const api = process.env.VUE_APP_PRETTY_BACKEND_URL;
      return `${api}/api/studios/${this.studioURL}/events/${this.event.id}/ical`;
    },
  },
  data() {
    return {
      loadError: null,
      registering: false,
      unregistering: false,
      accessCode: null,
    };
  },
  methods: {
    openLoginModal() {
      this.$modal.show(StudentLogin);
    },
    countdownEnded() {
      this.loadEvent();
    },
    dayOfWeek(date) {
      return EventUtils.shortDate(date, 'dddd');
    },
    shortDate(date) {
      return EventUtils.shortDate(date);
    },
    shortTime(datetime) {
      return EventUtils.shortTime(datetime);
    },
    joinSession() {
      this.markEventAttended();
      if (this.event.streaming_session_id) {
        this.$router.push({
          name: 'studio-live-stream',
          params: { studio_url: this.studioURL, event_id: this.eventId },
        });
      } else if (this.event.zoom_join_url) {
        window.open(this.event.zoom_join_url);
      } else if (this.event.google_meet_join_url) {
        window.open(this.event.google_meet_join_url);
      }
    },
    goToShop() {
      // if there is only one product to purchase, go directly to checkout
      if (this.eventProducts.length === 1) {
        this.selectedProduct = this.eventProducts[0].id;
        this.$router.push({
          name: 'studio-event-product-checkout',
          params: { product_id: this.selectedProduct },
        });
      } else {
        this.$router.push({ name: 'studio-event-select-product' });
      }
    },
    markEventAttended() {
      useEventStore()
        .markEventAttended({
          studioURL: this.studioURL,
          eventId: this.eventId,
          accessCode: this.accessCode,
        })
        .catch(error => {
          this.$notify({
            group: 'app',
            type: 'error',
            title: 'Failed to record event attendance',
            text: errorToMessage(error.response, `Request failed: ${error}`),
          });
        });
    },
    async registerForEvent() {
      this.registering = true;
      try {
        await useEventStore().registerForEvent({
          studioURL: this.studioURL,
          eventId: this.eventId,
        });
        this.$notify({
          group: 'app',
          type: 'success',
          title: this.$t('NOTIFICATIONS.TITLE.REGISTRATION_SUCCESSFUL'),
          text: this.$t('NOTIFICATIONS.TEXT.SUCCESSFULLY_REGISTERED_TO', {
            eventname: this.event.event_name,
          }),
        });
        useUserStore().studentPunches();
        this.refreshStudio();
      } catch (error) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Registration failed',
          text: errorToMessage(error.response, `Request failed: ${error}`),
        });
      } finally {
        this.registering = false;
      }
    },
    async registerForEventWaitlist() {
      this.registering = true;
      try {
        await useEventStore().registerForEventWaitlist({
          studioURL: this.studioURL,
          eventId: this.eventId,
        });
        this.$notify({
          group: 'app',
          type: 'success',
          title: this.$t(
            'NOTIFICATIONS.TITLE.WAITLIST_REGISTRATION_SUCCESSFUL'
          ),
          text: this.$t(
            'NOTIFICATIONS.TEXT.SUCCESSFULLY_REGISTERED_ON_WAITLIST_TO',
            {
              eventname: this.event.event_name,
            }
          ),
        });
        this.refreshStudio();
      } catch (error) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Waitlist registration failed',
          text: errorToMessage(error.response, `Request failed: ${error}`),
        });
      } finally {
        this.registering = false;
      }
    },
    async registerForRecording() {
      this.registering = true;
      try {
        await useEventStore().registerForRecording({
          studioURL: this.studioURL,
          eventId: this.eventId,
        });
        if (!this.isFreeEvent) {
          this.$notify({
            group: 'app',
            type: 'success',
            title: this.$t('NOTIFICATIONS.TITLE.REGISTRATION_SUCCESSFUL'),
          });
        }
        useUserStore().studentPunches();
        this.refreshStudio();
      } catch (error) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Registration failed',
          text: errorToMessage(error.response, `Request failed: ${error}`),
        });
      } finally {
        this.registering = false;
      }
    },
    async registerForFreeEvent({ email, first_name, last_name }) {
      this.registering = true;
      try {
        await useEventStore().registerForFreeEvent({
          studioURL: this.studioURL,
          eventId: this.eventId,
          email,
          first_name,
          last_name,
        });
        trackFBPixelStudioFreeEventRegistration(this.studio.fb_pixel_id, {
          event_name: this.event.event_name,
          event_id: this.event.id,
          event_date: formatDate(this.event.date_start, 'date'),
          event_time: formatDate(this.event.date_start, 'time'),
        });
        this.$notify({
          group: 'app',
          type: 'success',
          title: this.$t('NOTIFICATIONS.TITLE.REGISTRATION_SUCCESSFUL'),
          text: this.$t('NOTIFICATIONS.TEXT.SUCCESSFULLY_REGISTERED_TO', {
            eventname: this.event.event_name,
          }),
        });
        await this.$router.replace({
          name: 'studio-event-details',
          params: {
            event_id: this.event.id,
          },
          query: {
            access_code: this.event.access_code,
          },
        });
        this.accessCode = this.event.access_code;
        this.refreshStudio();
      } catch (error) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Registration failed',
          text: errorToMessage(error.response, `Request failed: ${error}`),
        });
      } finally {
        this.registering = false;
      }
    },
    showUnregisterFromEventModal() {
      this.$modal.show(HMDialog, {
        title: this.$t('GENERAL.TITLE.CONFIRM'),
        text: this.$t('EVENTS.TEXT.CONFIRM_UNREGISTER_FROM_EVENT', {
          eventname: this.event.event_name,
        }),
        confirmButton: this.$t('EVENTS.BUTTON.UNREGISTER'),
        confirm: async () => {
          await this.unregisterFromEvent();
        },
      });
    },
    async unregisterFromEvent() {
      this.unregistering = true;
      try {
        await useEventStore().unregisterFromEvent({
          studioURL: this.studioURL,
          eventId: this.eventId,
        });
        this.$notify({
          group: 'app',
          type: 'success',
          title: this.$t('NOTIFICATIONS.TITLE.UNREGISTRATION_SUCCESSFUL'),
          text: this.$t('NOTIFICATIONS.TEXT.SUCCESSFULLY_UNREGISTERED_FROM', {
            eventname: this.event.event_name,
          }),
        });
        useUserStore().studentPunches();
        this.refreshStudio();
      } catch (error) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Unregistration failed',
          text: errorToMessage(error.response, `Request failed: ${error}`),
        });
      } finally {
        this.unregistering = false;
      }
    },
    showUnregisterFromEventWaitlistModal() {
      this.$modal.show(HMDialog, {
        title: this.$t('GENERAL.TITLE.CONFIRM'),
        text: this.$t('EVENTS.TEXT.CONFIRM_UNREGISTER_FROM_WAITLIST', {
          eventname: this.event.event_name,
        }),
        confirmButton: this.$t('EVENTS.BUTTON.UNREGISTER'),
        confirm: async () => {
          await this.unregisterFromEventWaitlist();
        },
      });
    },
    async unregisterFromEventWaitlist() {
      this.unregistering = true;
      try {
        await useEventStore().unregisterFromEventWaitlist({
          studioURL: this.studioURL,
          eventId: this.eventId,
        });
        this.$notify({
          group: 'app',
          type: 'success',
          title: this.$t('NOTIFICATIONS.TITLE.UNREGISTRATION_SUCCESSFUL'),
          text: this.$t(
            'NOTIFICATIONS.TEXT.SUCCESSFULLY_UNREGISTERED_FROM_WAITLIST',
            {
              eventname: this.event.event_name,
            }
          ),
        });
        this.refreshStudio();
      } catch (error) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Waitlist unregistration failed',
          text: errorToMessage(error.response, `Request failed: ${error}`),
        });
      } finally {
        this.unregistering = false;
      }
    },
    async refreshStudio() {
      await useStudioStore().getStudio(this.studioURL);
    },
    eventLoaded() {
      if (this.event && this.justPurchased && this.canRegisterEvent) {
        this.registerForEvent();
        // remove query parameter from url
        const query = { ...this.$route.query };
        delete query.purchased;
        this.$router.replace({ query });
      }
    },
    async loadEvent() {
      try {
        await useEventStore().getStudioEventDetails({
          studioURL: this.studioURL,
          eventId: this.eventId,
          accessCode: this.accessCode,
        });
        // when event is loaded...
        this.eventLoaded();
      } catch (error) {
        this.loadError = this.$t('EVENTS.TEXT.WHOOPS_COULD_NOT_FIND_EVENT');
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Event load failed',
          text: errorToMessage(error.response, `Request failed: ${error}`),
        });
      }
    },
    async loadData() {
      if(this.event?.id != this.eventId) {
        useEventStore().setCurrentEvent({});
      }
      const waiver = await useStudioStore().getWaiverToBeSigned({
        studioURL: this.$studioURL,
        uuid: undefined,
      });
      let pendingContracts = [];
      if (this.isLoggedIn) {
        pendingContracts = await useStudioStore().getPendingSignedContracts(
          { studioURL: this.studioURL }
        );
      }
      this.loadEvent();
      if (waiver.id) {
        this.$modal.show(
          LegalWaiverModal,
          {
            showCloseButton: false,
            waiver,
          },
          { clickToClose: false, escToClose: false, contentClass: 'vm--inline-modal' }
        );
      }
      pendingContracts.forEach(contract => {
        this.$modal.show(
          SignContractModal,
          {
            signedContract: contract,
          },
          {
            clickToClose: false,
            escToClose: false,
            contentClass: ['full-width-modal'],
          }
        );
      });
    },
  },
  mounted() {
    this.accessCode = this.$route.query.access_code;
    this.loadData();
  },
  beforeUnmount() {
    this.$modal.hideAll();
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.info-card-header {
  min-height: 90px;
  padding: 16px 0;
}

.event-details-wrapper {
  .show-only-mobile {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  .hide-on-mobile {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.event-day-description {
  font-size: 16px;
  margin-bottom: 5px;
  display: block;
  @include media-breakpoint-up(md) {
    margin-right: 3px;
    display: inline-block;
  }
}

.event-time {
  font-size: 16px;
  font-weight: 600;
  display: block;
  @include media-breakpoint-up(md) {
    display: inline-block;
  }
}

.add-to-calendar {
  color: $dark-gray;
  cursor: pointer;
}

.event-name {
  &.cancelled {
    text-decoration: line-through;
  }
}

.event-type-description {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-content: center;
  margin-top: 3px;
  @include media-breakpoint-up(md) {
    font-size: 14px;
  }
}

.event-type-icon {
  padding-right: 7px;
}

.info-title {
  margin: 20px 0 10px;
  font-size: 14px;
  display: inline-block;
}

.products {
  margin-bottom: 30vh;
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

.products-title {
  font-weight: 600;
  font-size: 20px;
  color: #181818;
  margin: 30px 0 15px;
  display: inline-block;
  @include media-breakpoint-up(md) {
    font-size: 24px;
  }
}

.event-registrations {
  background-color: white;
  color: #181818;
  font-weight: 400;
  font-size: 14px;
  padding: 4px 16px;
  border-radius: 6px;
  border: 1px dotted darkgreen;
  max-width: max-content;
}

.live-tag {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
}

.live-icon {
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
}
.destyled-button {
  border: none;
  outline: none;
  background-color: transparent;
}
</style>